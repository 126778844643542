import { AxiosResponse } from "axios";
import dayjs, { Dayjs } from "dayjs";

import MobileWebApiClient from "./base/MobileWebApiClient";
import { Attachment } from "./Common/CommonTypes";

export type SPFileSystemItem = {
  isFolder: boolean;
  address: string;
  documentType: string;
  modifiedBy: string;
  modified: Date;
  localName: string;
  fullPath: string;
  serverRelativePath: string;
  modifiedByName: string;
  childItems: Array<SPFileSystemItem>;
  currentVersion: boolean;
  disclosureExpDate: Date | null;
};

export type DisclosureDocumentItem = {
  isFolder: boolean;
  address: string;
  documentType: string;
  modifiedBy: string;
  modified: Date;
  localName: string;
  fullPath: string;
  serverRelativePath: string;
  modifiedByName: string;
  currentVersion: boolean;
  disclosureExpDate: Date | null;
};

export type AssociationDocumentItem = {
  id: number;
  title: string;
  documentType: string;
  month: string;
  year: string;
  created: Date;
  name: string;
  fileUrl: string;
  modifiedByName: string;
  modified: Date;
};

export type ArchitecturalSubmittal = {
  id: number;
  title: string;
  receivedDate: Date;
  dueDate: Date;
  modificationType: string;
  architecturalStatus: string;
  archSubWF: string;
  priority: string;
  propertyAddress: string;
  assignedTo: Array<number>;
  taskDescription: string;
  architecturalProcess: string;
  taskStatus: string;
  note: string;
  noteHistory: string;
  attachments: Array<string>;
};

export type AnnouncementItem = {
  id: number;
  title: string;
  editorId: number;
  authorId: number;
  modified: Date;
  created: Date;
  authorName: string;
  editorName: string;
  body: string;
  expires: Date | null;
};

export type VendorAgreementItem = {
  ContractStartDate: Date;
  ContractTermExpirationDate: Date;
  CommunityContractStatus: string;
  CommunityFileType: string;
  CommunityContractType: string;
  CurrentVersion: boolean;
  id: number;
  title: string;
  editorId: number;
  authorId: number;
  modified: Date;
  created: Date;
  fileRef: string;
};

export type DocumentFilter = {
  displayName: string;
  items: string[];
};

export type TaxonomyItemsInOffice = {
  office: string;
  items: Array<TaxonomyItem>;
};

export type TaxonomyItem = {
  id: number;
  title: string;
  name: string;
  email: string;
  loginName: string;
  termGuid: string;
  isSelected: boolean;
};

export type LocalTask = {
  id: number;
  title: string;
  startDate: any;
  dueDate: any;
  modified: string;
  reoccurrence: string;
  assignedTo: Array<number>;
  usersAssignedTo: string;
  communityBoardTaskType: number;
  communityBoardTaskTypeTermGuid: string;
  requestedBy: string;
  taskDescription: string;
  priority: String;
  internalTaskStatus: string;
  taskStatus: string;
  percentComplete: string;
  completionDate: any;
  communityCommittee: number;
  communityCommitteeTermGuid: string;
  note: string;
  attachments: Array<Attachment>;
};

export type TasksListBlock = {
  taskList: Array<LocalTask>;
  nextBlockUrl: string;
};

export type FileDataUpload = {
  data: any;
  folderPath: string;
  name: string;
  communityId: string;
  office: string;
  email: string;
};
export type CalendarEvent = {
  category: string;
  description: string;
  endDate: Dayjs;
  eventDate: Dayjs;
  fAllDayEvent: boolean;
  fRecurrence: boolean;
  id: number;
  location: string;
  title: string;
  recurrencePattern: EventRepeatInfo;
  repeatingEndDateForm: Dayjs;
  categoryCustom?: string;
};

export enum Months {
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
}

export enum WeeklyDays {
  su = "su",
  mo = "mo",
  tu = "tu",
  we = "we",
  th = "th",
  fr = "fr",
  sa = "sa",
}

export enum WeekdayOfMonth {
  first = "first",
  second = "second",
  third = "third",
  fourth = "fourth",
}

export enum Period {
  Month = "Month",
  Week = "Week",
  Today = "Today",
}

export enum RepeatType {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
  monthlyByDay = "monthlyByDay",
  yearly = "yearly",
  yearlyByDay = "yearlyByDay",
}

export type EventRepeatInfo = {
  repeatType: RepeatType;
  frequency: number;
  dayNumber: number;
  monthNumber: number;
  weeklyDays: WeeklyDays[];
  oneDayOfWeek: WeeklyDays;
  weekdayOfMonth: WeekdayOfMonth;
  endlessRepeating: boolean;
  repeatingEndDate?: Dayjs;
  repeatCount: number;
  dailyWeekday: boolean;
};

export type BvReportsDataRequest = {
  Name: string;
  RequestParameter?: string;
};

export type BvReportsDataResponseItem = {
  singleVal: number;
  monthlyResultTable?: MonthlyResultItem[];
  typedResultTable?: TypedResultItem[];
};

export type MonthlyResultItem = {
  value: number;
  monthNum: number;
  year: number;
};

export type TypedResultItem = {
  value: number;
  name: string;
};

export default class BoardVueApi extends MobileWebApiClient {
  public async getFilesListInFolder(
    folderName: string
  ): Promise<SPFileSystemItem[]> {
    try {
      var res = await this.tryPost("sharepoint/getfileslistinfolder", {
        item1: this._configApi.community.communityId,
        Item2: this._configApi.community.office,
        item3: folderName,
      });
      if (res.statusText === "OK" && res.data) {
        return res.data.childItems;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching BoardDocuments:", error);
      return [];
    }
  }

  public async searchFilesListInFolder(
    searchString: string
  ): Promise<SPFileSystemItem[]> {
    try {
      var res = await this.tryPost("sharepoint/searchfilesbyname", {
        item1: this._configApi.community.communityId,
        Item2: this._configApi.community.office,
        item3: searchString,
      });
      if (res.statusText === "OK" && res.data) {
        return res.data.childItems;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching BoardDocuments:", error);
      return [];
    }
  }

  public async getAssociationDocuments(): Promise<
    AxiosResponse<Array<AssociationDocumentItem>>
  > {
    return await this.tryPost("sharepoint/getassociationdocuments", {
      item1: this._configApi.community.communityId,
      item2: this._configApi.community.office,
    });
  }

  public async getAnnouncements(): Promise<Array<AnnouncementItem>> {
    var res = await this.tryPost("sharepoint/getannouncementlist", {
      item1: this._configApi.community.communityId,
      item2: this._configApi.community.office,
    });

    return res.data.map(
      (item: AnnouncementItem): AnnouncementItem => ({
        ...item,
        modified: new Date(item.modified),
        created: new Date(item.created),
        expires: item.expires === null ? null : new Date(item.expires),
      })
    );
  }

  public getAnnouncement = async (
    id: number
  ): Promise<AnnouncementItem | null> => {
    try {
      const response = await this.tryPost("sharepoint/getannouncementitem", {
        item1: this._configApi.community.communityId,
        item2: this._configApi.community.office,
        item3: id,
      });
      if (response.statusText === "OK" && response.data) {
        const { modified, created, expires, ...announcement } = response.data;
        return {
          ...announcement,
          modified: dayjs(modified),
          created: dayjs(created),
          expires: expires ? dayjs(expires) : undefined,
        } as AnnouncementItem;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching announcement:", error);
      return null;
    }
  };

  public async getVendorAgreements(): Promise<Array<VendorAgreementItem>> {
    try {
      var res = await this.tryPost("sharepoint/getvendoragreementList", {
        item1: this._configApi.community.communityId,
        item2: this._configApi.community.office,
      });
      if (res.statusText === "OK" && res.data) {
        return res.data.map(
          (item: VendorAgreementItem): VendorAgreementItem => ({
            ...item,
            ContractStartDate: new Date(item.ContractStartDate),
            ContractTermExpirationDate: new Date(item.ContractTermExpirationDate),
            modified: new Date(item.modified),
            created: new Date(item.created),
          })
        );
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching BoardDocuments:", error);
      return [];
    }
  }

  public async getDisclosuresList(): Promise<DisclosureDocumentItem[]> {
    try {
      var res = await this.tryPost("sharepoint/getdisclosureslist", {
        item1: this._configApi.community.communityId,
        Item2: this._configApi.community.office,
      });
      if (res.statusText === "OK" && res.data) {
        return res.data.map(
          (item: DisclosureDocumentItem): DisclosureDocumentItem => ({
            ...item,
            modified: new Date(item.modified),
            disclosureExpDate: item.disclosureExpDate
              ? new Date(item.disclosureExpDate)
              : null,
          })
        );
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching BoardDocuments:", error);
      return [];
    }
  }

  public getTaskList = async (
    listTitle: string,
    status: string,
    searchString: string
  ): Promise<AxiosResponse<Array<AssociationDocumentItem>>> => {
    return await this.tryPost("sharepoint/gettasklistbystatuswithfilter", {
      item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      item3: "Board Tasks",
      item4: status,
      item5: searchString,
    });
  };

  public getSharePointRecordArchiveFilters = async (): Promise<
    AxiosResponse<Array<DocumentFilter>>
  > => {
    return await this.tryPost(
      "sharepoint/getrecordarchivefilters",
      String(this._configApi.community.communityId)
    );
  };

  public getAllSharePointArchiveItems = async (
    item2: string[],
    Item3: string | null = ""
  ): Promise<AxiosResponse> => {
    return await this.tryPost("sharepoint/GetRecordArchiveItems", {
      Item1: {
        Id: 10257,
        CommunityId: this._configApi.community.communityId,
        Name: this._configApi.community.name,
        Address: "4216 W Ardmore Rd",
        ResidentKey: 1200782,
        IsVms: 1,
        Role: 1,
        Office: this._configApi.community.office,
        Phone: "",
        UserId: 163,
      },
      Item2: item2,
      Item3: Item3,
    });
  };

  public getGetCalendarItems = async (
    startDate: Date,
    period: Period = Period.Month
  ): Promise<AxiosResponse> => {
    return await this.tryPost("sharepoint/GetCalendarItems", {
      Item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      Item3: startDate.toLocaleDateString("en-US"),
      Item4: period,
    });
  };

  public getTaskStatusesList = async (): Promise<
    AxiosResponse<Array<AssociationDocumentItem>>
  > => {
    return await this.tryPost("sharepoint/gettaskstatuseslist", {
      item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      Item3: "Board Tasks",
    });
  };

  public getTaskListByStatusWithFilter = async (
    status: string[]
  ): Promise<LocalTask[]> => {
    var localTasks: LocalTask[] = [];
    var responce = await this.tryPost("sharepoint/getboardtasklist", {
      Item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      Item3: status,
      Item4: "",
    });

    var tasks = responce.data.taskList?.map(
      (item: LocalTask): LocalTask => ({
        ...item,
        startDate: dayjs(item.startDate),
        dueDate: dayjs(item.dueDate),
        completionDate:
          item.completionDate === null ? undefined : dayjs(item.completionDate),
      })
    );
    localTasks = tasks;
    let nextBlockUrl = responce.data.nextBlockUrl;
    while (nextBlockUrl) {
      const nextResponse = await this.getTaskListByNextUrl(nextBlockUrl);
      nextBlockUrl = nextResponse.data.nextBlockUrl;
      var nextTasks = nextResponse.data.taskList?.map(
        (item: LocalTask): LocalTask => ({
          ...item,
          startDate: dayjs(item.startDate),
          dueDate: dayjs(item.dueDate),
          completionDate:
            item.completionDate === null
              ? undefined
              : dayjs(item.completionDate),
        })
      );
      localTasks = [...localTasks, ...nextTasks];
    }
    return localTasks;
  };

  public getTaskListByNextUrl = async (
    nextBlockUrl: string
  ): Promise<AxiosResponse<TasksListBlock, any>> => {
    return await this.tryPost("sharepoint/gettasklistblock", {
      Item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      Item3: nextBlockUrl,
    });
  };

  public getTaskById = async (taskId: number): Promise<LocalTask | null> => {
    try {
      const response = await this.tryPost("sharepoint/getboardtaskitem", {
        item1: this._configApi.community.communityId,
        item2: this._configApi.community.office,
        item3: taskId,
      });
      if (response.statusText === "OK" && response.data) {
        const { startDate, dueDate, completionDate, ...task } = response.data;
        return {
          ...task,
          startDate: dayjs(startDate),
          dueDate: dayjs(dueDate),
          completionDate:
            completionDate === null ? undefined : dayjs(completionDate),
        } as LocalTask;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching task:", error);
      return null;
    }
  };
  public getEventById = async (
    taskId: number,
    taskData: Dayjs | null
  ): Promise<CalendarEvent | null> => {
    try {
      const response = await this.tryPost("sharepoint/GetCalendarEvent", {
        item1: this._configApi.community.communityId,
        item2: this._configApi.community.office,
        item3: taskId,
        item4: taskData,
      });
      if (response.statusText === "OK" && response.data) {
        const {
          eventDate,
          endDate,
          recurrencePattern,
          fAllDayEvent,
          fRecurrence,
          ...event
        } = response.data;

        var x = new Date();
        var currentTimeZoneOffsetInHours = (x.getTimezoneOffset() / 60) * -1;

        return {
          ...event,
          fAllDayEvent: fAllDayEvent,
          fRecurrence: fRecurrence,
          ...(fAllDayEvent
            ? {
                eventDate: dayjs(eventDate)
                  .set("hour", 12 + currentTimeZoneOffsetInHours)
                  .set("minute", 0),
              }
            : { eventDate: dayjs(eventDate) }),
          endDate: dayjs(endDate),
          ...(recurrencePattern && {
            recurrencePattern: {
              ...recurrencePattern,
              ...(recurrencePattern.repeatingEndDate && {
                repeatingEndDate: dayjs(recurrencePattern.repeatingEndDate)
                  .set("hour", 23)
                  .set("minute", 59),
              }),
            },
          }),
          ...(recurrencePattern?.repeatingEndDate && {
            repeatingEndDateForm: recurrencePattern?.repeatingEndDate
              ? dayjs(recurrencePattern?.repeatingEndDate)
                  .set("hour", 23)
                  .set("minute", 59)
              : dayjs(endDate).set("hour", 23).set("minute", 59),
          }),
        } as CalendarEvent;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching task:", error);
      return null;
    }
  };

  public getUsers = async (): Promise<AxiosResponse<Array<TaxonomyItem>>> => {
    return await this.tryPost(
      "sharepoint/GetUsersFromOffice",
      this._configApi.community.office
    );
  };

  public getSharePointField = async (
    internalName: string
  ): Promise<AxiosResponse<Array<string>>> => {
    return await this.tryPost("sharepoint/getfield", {
      item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      Item3: "Board Tasks",
      Item4: internalName,
    });
  };

  public getSharePointTaskAttachments = async (
    taskId: number
  ): Promise<AxiosResponse> => {
    return await this.tryPost("sharepoint/getattachementlistfortask", {
      Item1: taskId,
      Item2: this._configApi.community.communityId,
      Item3: this._configApi.community.office,
    });
  };

  public getSharePointCommunityBoardTaskTypes = async () => {
    var res = await this.tryPost(
      "sharepoint/getcommunityboardtasktypesbyoffices"
    );
    return (
      res.status === 200
        ? res.data.filter(
            (_: { office: string }) =>
              _.office === this._configApi.community.office
          )[0].items
        : []
    ) as TaxonomyItem[];
  };

  public getSharePointCommunityCommittees = async () => {
    var res = await this.tryPost("sharepoint/getcommunitycommitteesbyoffices");
    return (
      res.status === 200
        ? res.data.filter(
            (_: { office: string }) =>
              _.office === this._configApi.community.office
          )[0].items
        : []
    ) as TaxonomyItem[];
  };

  public addSharePointTask = async (
    task: LocalTask
  ): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost("sharepoint/addtask", {
      Item1: task,
      Item2: this._configApi.community.communityId,
      Item3: this._configApi.community.office,
    });
  };

  public updateSharePointTask = async (
    task: LocalTask
  ): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost("sharepoint/updatetask", {
      Item1: task,
      Item2: this._configApi.community.communityId,
      Item3: this._configApi.community.office,
    });
  };

  public addCalendarEvent = async (
    event: CalendarEvent
  ): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost("sharepoint/AddCalendarEvent", {
      Item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      Item3: event,
    });
  };

  public updateCalendarEvent = async (
    event: any
  ): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost("sharepoint/UpdateCalendarEvent", {
      Item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      Item3: event,
    });
  };

  public deleteCalendarEvent = async (
    eventId: string
  ): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost("sharepoint/DeleteCalendarEvent", {
      Item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
      Item3: eventId,
    });
  };

  public uploadFile = async (
    fileData: FileDataUpload
  ): Promise<AxiosResponse> => {
    return await this.tryPost("sharepoint/uploadfile", fileData);
  };

  public getFile = async (url: string): Promise<AxiosResponse> => {
    return await this.tryPost("sharepoint/getfile", {
      Item1: url,
      Item2: this._configApi.community.communityId,
      Item3: this._configApi.community.office,
    });
  };

  public async GetBvReportsData(
    initData: BvReportsDataRequest[]
  ): Promise<AxiosResponse<BvReportsDataResponseItem[], any>> {
    const postArray = initData.map((item: BvReportsDataRequest) => {
      return {
        Name: item.Name,
        RequestParameter: item.RequestParameter ? item.RequestParameter : "",
        PropertyId: this._configApi.community.communityId,
      };
    });
    return await this.tryPost(
      "community/GetBvReportsData",
      JSON.stringify(postArray)
    );
  }

  public async GetBvDashboardSettings(): Promise<AxiosResponse> {
    var res = await this.tryGet(
      `user/GetBvDasboardSettings?userEmail=${this._configApi.userEmail}`
    );
    return res;
  }

  public async UpdateBvDashboardSettings(json: string): Promise<AxiosResponse> {
    var res = await this.tryPost("user/UpdateBvDasboardSettings", {
      Item1: this._configApi.userEmail,
      Item2: json,
    });
    return res;
  }
  public async GetArchitecturalSubmittalsList(): Promise<AxiosResponse>{
    const res = await this.tryPostLocalHost(`/sharepoint/GetArchitecturalSubmittalsList`,{
      Item1: this._configApi.community.communityId,
      Item2: this._configApi.community.office,
    });
    return res.data;
  }
  public async UpdateArchitecturalSubmittal(submittal : ArchitecturalSubmittal): Promise<AxiosResponse>{
    const res = await this.tryPostLocalHost(`/sharepoint/UpdateArchitecturalSubmittal`,{
      Item1: submittal,
      Item2: this._configApi.community.communityId,
      Item3: this._configApi.community.office,
    });
    return res.data;
  }
  public async AddAnnouncementItem(announcement : AnnouncementItem): Promise<AxiosResponse>{
    const res = await this.tryPostLocalHost(`/sharepoint/AddAnnouncementItem`,{
      Item1: announcement,
      Item2: this._configApi.community.communityId,
      Item3: this._configApi.community.office,
    });
    return res.data;
  }
  public async GetAllPermissionsForBoardUser(userId : string): Promise<AxiosResponse> {

    return await this.tryGetLocalhost(`boardvue/GetPermissionsForBoardVueUser?userId=${userId}`);
    
  }

  public async GetAllBoardVuePermissions(): Promise<AxiosResponse> {
    return await this.tryGetLocalhost(`boardvue/GetAllBoardVuePermissions`);
  }
}
