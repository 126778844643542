import { Navigate } from "react-router-dom";
import { Flex, Image } from "antd";
import docs from "../images/Docs.png";
import dashboard from "../images/Dashboard.png";
import documents from "../images/Vector.png";
import tasks from "../images/Check.png";
import archive from "../images/Archive.png";
import reports from "../images/Reports.png";
import announcement from "../images/Announcement.png";
import calendar from "../images/Calendar.png";
import bv_logo from "../images/bv_logo.png";
import AssociationDocuments from "../pages/BoardVueGroup/AssociationDocuments/AssociationDocuments";
import Announcements from "../pages/BoardVueGroup/Announcements/Announcements";
import VendorAgreements from "../pages/BoardVueGroup/VendorAgreements/VendorAgreements";
import BoardDocuments from "../pages/BoardVueGroup/BoardDocuments/BoardDocuments";
import Disclosures from "../pages/BoardVueGroup/Disclosures/Disclosures";
import BoardTasks from "../pages/BoardVueGroup/BoardTasks/BoardTasks";
import EditTask from "../pages/BoardVueGroup/BoardTasks/EditTask";
import CreateTask from "../pages/BoardVueGroup/BoardTasks/CreateTask";
import Dashboard from "../pages/BoardVueGroup/Dashboard/Dashboard";
import Reports from "../pages/BoardVueGroup/Reports/Reports";
import CalendarTasks from "../pages/BoardVueGroup/Calendar/CalendarTasks";
import RIC from "../pages/BoardVueGroup/RIC/RIC";
import Announcement from "../pages/BoardVueGroup/Announcements/Announcement";
import RecordArchive from "../pages/BoardVueGroup/RecordArchive/RecordArchive";
import {
  RouteElement,
  RouteGroupElement,
} from "../stores/appStores/ApplicationStore";
import FAQsReport from "../pages/BoardVueGroup/Reports/FAQsReport";
import GeneralLedgerReport from "../pages/BoardVueGroup/Reports/GeneralLedgerReport";
import CreateEvent from "../pages/BoardVueGroup/Calendar/CreateEvent";
import EditEvent from "../pages/BoardVueGroup/Calendar/EditEvent";
import { Role } from "../api/Common/CommonTypes";
import ResidentMoveInReport from "../pages/BoardVueGroup/Reports/ResidentMoveInReport";
import ResidentDirectoryReport from "../pages/BoardVueGroup/Reports/ResidentDirectoryReport";
import BodAndCommitteeDirectoryReport from "../pages/BoardVueGroup/Reports/BodAndCommitteeDirectoryReport";
import CommunityVendorListReport from "../pages/BoardVueGroup/Reports/CommunityVendorListReport";
import CurrentBalancesWithAgingByResidentReport from "../pages/BoardVueGroup/Reports/CurrentBalancesWithAgingByResidentReport";
import ArchitecturalStatusCSVExportReport from "../pages/BoardVueGroup/Reports/ArchitecturalStatusCSVExportReport";
import VMSTrusteeSalesProgressReport from "../pages/BoardVueGroup/Reports/VMSTrusteeSalesProgressReport";
import ViolationChartReport from "../pages/BoardVueGroup/Reports/ViolationChartReport";
import DelinquencyDetailLienAndDemandReport from "../pages/BoardVueGroup/Reports/DelinquencyDetailLienAndDemandReport";
import DelinquencySummaryReport from "../pages/BoardVueGroup/Reports/DelinquencySummaryReport";
import DelinquencyDetailCheckboxSummaryReport from "../pages/BoardVueGroup/Reports/DelinquencyDetailCheckboxSummaryReport";
import PaidInvoicesReport from "../pages/BoardVueGroup/Reports/PaidInvoicesReport";
import BalanceWithCategoriesByResidentReport from "../pages/BoardVueGroup/Reports/BalanceWithCategoriesByResidentReport";
import ComplianceDetailReport from "../pages/BoardVueGroup/Reports/ComplianceDetailReport";
import BVHowToGuide from "../pages/BoardVueGroup/BVHowToGuide";
import ArchitecturalStatusReport from "../pages/BoardVueGroup/Reports/ArchitecturalStatusReport";
import ArchitecturalSubmittalsList from "../pages/BoardVueGroup/Architectural/ArchitecturalSubmittalsList";

const iconStyle:React.CSSProperties = {width:"1.2em", marginLeft: "10px"}

const boardvueChildrenRoutes: RouteElement[] = [
  {
    key: "/",
    path: "",
    element: <Navigate to={`/`} />,
    featureID: null,
  },
  {
    key: "/Dashboard",
    path: "/Dashboard",
    icon: <Flex><Image style={iconStyle} src={dashboard} preview={false} /></Flex>,
    label: "Dashboard",
    element: <Dashboard />,
    featureID: 32,
  },
  {
    key: "/Announcements",
    path: "/Announcements",
    icon: <Flex><Image style={iconStyle} src={announcement} preview={false} /></Flex>,
    label: "Announcements",
    element: <Announcements />,
    featureID: 33,
  },
  {
    key: "/Announcement/:announcementId",
    path: "/Announcement/:announcementId",
    element: <Announcement />,
    featureID: 33,
  },
{
  key: "/Calendar",
  path: "/Calendar",
  icon: <Flex><Image style={iconStyle} src={calendar} preview={false} /></Flex>,
  label: "Calendar",
  element: <CalendarTasks />,
  featureID: 34,
},
{
  key: '/Calendar/Create',
  path: '/Calendar/Create',
  element: <CreateEvent />,
  featureID: 34,
},
{
  key: '/Calendar/Edit/:eventId',
  path: '/Calendar/Edit/:eventId',
  element: <EditEvent />,
  featureID: 34
},
  {
    key: "/BoardTasks",
    path: "/BoardTasks",
    icon: <Flex><Image style={iconStyle} src={tasks} preview={false} /></Flex>,
    label: "Board Tasks",
    element: <BoardTasks />,
    featureID: 35,
  },
  {
    key: "/BoardTasks/Edit/:taskId",
    path: "/BoardTasks/Edit/:taskId",
    element: <EditTask />,
    featureID: 35,
  },
  {
    key: "/BoardTasks/Create",
    path: "/BoardTasks/Create",
    element: <CreateTask />,
    featureID: 35,
  },
  
  {
    key: "/BoardDocuments",
    path: "/BoardDocuments",
    icon: <Flex><Image style={iconStyle} src={documents} preview={false} /></Flex>,
    label: "Board Documents",
    element: <BoardDocuments />,
    featureID: 36,
  },
  {
    key: "/AssociationDocuments",
    path: "/AssociationDocuments",
    icon: <Flex><Image style={iconStyle} src={docs} preview={false} /></Flex>,
    label: "Association Documents",
    element: <AssociationDocuments />,
    featureID: 37,
  },
  {
    key: "/Disclosures",
    path: "/Disclosures",
    icon: <Flex><Image style={iconStyle} src={docs} preview={false} /></Flex>,
    label: "Disclosures",
    element: <Disclosures />,
    featureID: 38,
  },
  {
    key: "/VendorAgreements",
    path: "/VendorAgreements",
    icon: <Flex><Image style={iconStyle} src={docs} preview={false} /></Flex>,
    label: "Vendor Agreements",
    element: <VendorAgreements />,
    featureID: 39,
  },
  {
    key: "/Reports",
    path: "/Reports",
    icon: <Flex><Image style={iconStyle} src={reports} preview={false} /></Flex>,
    label: "Reports",
    element: <Reports />,
    featureID: 40,
  },
  {
    key: "/GeneralLedgerReport",
    path: "/Reports/GeneralLedgerReport",
    element: <GeneralLedgerReport />,
    featureID: 40,
  },
  {
    key: "/Reports/ResidentMoveIn",
    path: "/Reports/ResidentMoveIn",
    element: <ResidentMoveInReport />,
    featureID: 40,
  },
  {
    key: "/Reports/ResidentDirectory",
    path: "/Reports/ResidentDirectory",
    element: <ResidentDirectoryReport />,
    featureID: 40,
  },
  {
    key: "/Reports/BodAndCommitteeDirectory",
    path: "/Reports/BodAndCommitteeDirectory",
    element: <BodAndCommitteeDirectoryReport />,
    featureID: 40,
  },
  {
    key: "/Reports/CommunityVendorList",
    path: "/Reports/CommunityVendorList",
    element: <CommunityVendorListReport />,
    featureID: 40,
  },
  {
    key: "/Reports/CurrentBalancesWithAgingByResident",
    path: "/Reports/CurrentBalancesWithAgingByResident",
    element: <CurrentBalancesWithAgingByResidentReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/ArchitecturalStatusCSVExport",
    path: "/Reports/ArchitecturalStatusCSVExport",
    element: <ArchitecturalStatusCSVExportReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/ArchitecturalStatus",
    path: "/Reports/ArchitecturalStatus",
    element: <ArchitecturalStatusReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/VMSTrusteeSalesProgress",
    path: "/Reports/VMSTrusteeSalesProgress",
    element: <VMSTrusteeSalesProgressReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/ViolationChart",
    path: "/Reports/ViolationChart",
    element: <ViolationChartReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/DelinquencyDetailLienAndDemand",
    path: "/Reports/DelinquencyDetailLienAndDemand",
    element: <DelinquencyDetailLienAndDemandReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/DelinquencySummary",
    path: "/Reports/DelinquencySummary",
    element: <DelinquencySummaryReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/DelinquencyDetailCheckboxSummary",
    path: "/Reports/DelinquencyDetailCheckboxSummary",
    element: <DelinquencyDetailCheckboxSummaryReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/PaidInvoices",
    path: "/Reports/PaidInvoices",
    element: <PaidInvoicesReport />,
    featureID: 40,
  }, 
  {
    key: "/Reports/BalanceWithCategoriesByResident",
    path: "/Reports/BalanceWithCategoriesByResident",
    element: <BalanceWithCategoriesByResidentReport />,
    featureID: 40,
  },
  {
    key: "/Reports/ComplianceDetail",
    path: "/Reports/ComplianceDetail",
    element: <ComplianceDetailReport />,
    featureID: 40,
  },
  
  {
    key: "/FAQsReport",
    path: "/Reports/FAQsReport",
    element: <FAQsReport />,
    featureID: 40,
  },
  {
    key: "/RICAccess",
    path: "/RICAccess",
    icon: <Flex><Image style={iconStyle} src={archive} preview={false} /></Flex>,
    label: "Resident Info Console",
    element: <RIC />,
    featureID: 41,
  },
  {
    key: "/RecordArchive",
    path: "/RecordArchive",
    icon: <Flex><Image style={iconStyle} src={documents} preview={false} /></Flex>,
    label: "Record Archive",
    element: <RecordArchive />,
    featureID: 42,
  },
  {
    key: "/RecordArchive/:id",
    path: "/RecordArchive/:id",
    icon: <Flex><Image style={iconStyle} src={documents} preview={false} /></Flex>,
    element: <RecordArchive />,
    featureID: 42,
  },
  {
    key: "/HowtoGuideBV2",
    path: "/HowtoGuideBV2",
    icon: <Flex><Image style={iconStyle} src={documents} preview={false} /></Flex>,
    label: "BoardVue User Guide",
    element: <BVHowToGuide />,
    featureID: null,
  },
  {
    key: "/ArchitecturalSubmittalsList",
    path: "/ArchitecturalSubmittalsList",
    icon: <Flex><Image style={iconStyle} src={docs} preview={false} /></Flex>,
    label: "Architectural Submittal",
    element: <ArchitecturalSubmittalsList />,
    featureID: 43,
  },  
];
const BoardVueRoutes: RouteGroupElement[] = [
  {
    key: "/BoardVue",
    path: "/BoardVue",
    icon: <Flex style={{marginLeft:"-2px", marginRight: "5px"}}><Image width="20px" src={bv_logo} preview={false} /></Flex>, 
    label: "BoardVue",
    children: boardvueChildrenRoutes,
    role: [Role.BoardMember],
    className: "boardvue-elements",
  },
];

export default BoardVueRoutes;
